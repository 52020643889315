/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Slogan from '../page-components/Index/Slogan';
import Programs from '../page-components/Index/Programs';
import AboutUs from '../page-components/Index/AboutUs';
import OurPartners from '../page-components/Index/OurPartners/index';
import News from '../page-components/Index/News';
import Contact from '../page-components/Index/Contact';
import Press from '../page-components/Index/Press';

import '../page-styles/index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function IndexPage() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout isTransparent>
      <Seo title={t('title')} />
      <div className="index">
        <Slogan />
        <Programs />
        <AboutUs />
        <OurPartners />
        <News />
        <Press />
        <Contact />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
