/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import Link from '../Link/index';
import Image from '../../Image';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SliderItem({
  ProgramData: { SlideTitle, SlideDesc, linkContent, linkURL, imgURL, LogoURL },
}) {
  /* ******************************** CONSTS ******************************* */
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="slider-item" fluid>
      <div className="slider-item__Image-container">
        <Image src={imgURL} alt="Slider-img" />
      </div>
      <div className="slider-item__Content">
        <Image src={LogoURL} alt="Program" />
        <h2>{SlideTitle}</h2>
        <p>{SlideDesc}</p>
        <Link to={linkURL}>{linkContent}</Link>
      </div>
    </Container>
  );
}

SliderItem.propTypes = {
  ProgramData: PropTypes.shape({
    SlideTitle: PropTypes.string,
    SlideDesc: PropTypes.string,
    linkContent: PropTypes.string,
    linkURL: PropTypes.string,
    imageURL: PropTypes.string,
    imgURL: PropTypes.string,
    LogoURL: PropTypes.string,
  }).isRequired,
};
SliderItem.defaultTypes = {};

export default SliderItem;
