/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import SubTitle from '../../../shared/UIKit/SubTitle';
import Button from '../../../shared/UIKit/Button';
import Image from '../../../shared/Image';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Contact() {
  // localisation
  const { t } = useTranslation();

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const { title, formTitle, formParagraph, inputs, buttonText } = t('contact', {
    returnObjects: true,
  });

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const InputsMap = inputs?.map(({ id, name, placeholder, type }) => (
    <div key={id} className="contact-form-item">
      <label htmlFor={name}>{placeholder}</label>
      <input
        type={type || 'text'}
        placeholder={placeholder}
        className="contact-form__input"
        name={name}
        required
      />
    </div>
  ));

  /* ******************************** RENDERING ******************************* */

  return (
    <section className="contact bg-gray ">
      <span id="contact-section" />
      <div className="container scroll-reveal">
        <SubTitle>{title}</SubTitle>
        <Container fluid>
          <Row align="center">
            <Col lg={6} md={12}>
              <p className="contact__title">{formTitle}</p>
              <p className="contect__paragraph">{formParagraph}</p>
              <form
                className="contact-form"
                action="https://formspree.io/f/mjvjrkpe"
                id="form"
                method="POST"
              >
                <input
                  type="hidden"
                  name="_subject"
                  value="Contact Fondation Tunisie"
                />
                {InputsMap}
                <div className="contact-form__button">
                  <Button isSubmit>{buttonText}</Button>
                </div>
              </form>
            </Col>
            <Col lg={6} md={12}>
              <div className="contact-img-container">
                <Image src="contact-img.png" alt="contact image" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
