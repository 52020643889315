/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container, useScreenClass } from 'react-grid-system';

// Local UI components
import SubTitle from '../../../shared/UIKit/SubTitle';
// Helpers & utils
// Style
import './index.scss';
// APIS

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function News() {
  /* ********************************** HOOKS ********************************* */
  const screen = useScreenClass();
  // State
  // localisation
  const { t } = useTranslation();
  /* --------------------------------- HELPERS -------------------------------- */
  // get News List:

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const { titleSection } = t('news', { returnObjects: true });
  /* ----------------------------- RENDER HELPERS ----------------------------- */

  /* ******************************** RENDERING ******************************* */

  return (
    <section className="bg-gray" id="News">
      <div className="scroll-reveal">
        <Container fluid>
          <SubTitle>{titleSection}</SubTitle>
          <div className="news-iframe">
            {screen === 'sm' && (
              <iframe
                title="facebook page"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFondationTunisie&tabs=timeline&width=400&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="400"
                height="1000"
                style={{ border: 'none', overflow: 'hidden' }}
                allowFullScreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            )}
            {screen === 'xs' && (
              <iframe
                title="facebook page"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFondationTunisie&tabs=timeline&width=300&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="300"
                height="1000"
                style={{ border: 'none', overflow: 'hidden' }}
                allowFullScreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            )}
            {screen !== 'xs' && screen !== 'sm' && (
              <iframe
                title="facebook page"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFondationTunisie&tabs=timeline&width=500&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="500"
                height="1000"
                style={{ border: 'none', overflow: 'hidden' }}
                allowFullScreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            )}
          </div>
        </Container>
      </div>
    </section>
  );
}

News.propTypes = {};

News.defaultProps = {};

export default News;
