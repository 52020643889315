/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Link({ children, to: url }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <GatsbyLink to={url} className="link">
      {children}
    </GatsbyLink>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {};

export default Link;
