/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SliderPress({ slideTitle, slideDesc, imgURL, navigationPath }) {
  /* ******************************** RENDERING ******************************* */

  return (
    <Container
      className="slider-press"
      fluid
      onDoubleClick={() => navigate(navigationPath)}
    >
      <div className="slider-press__Image-container">
        <img src={imgURL} alt="" />
      </div>
      <div className="slider-press__Content">
        <h2>{slideTitle}</h2>
        <p>{slideDesc}</p>
      </div>
    </Container>
  );
}

SliderPress.propTypes = {
  slideTitle: PropTypes.string.isRequired,
  slideDesc: PropTypes.string.isRequired,
  imgURL: PropTypes.string.isRequired,
  navigationPath: PropTypes.string.isRequired,
};

SliderPress.defaultProps = {};

export default SliderPress;
