/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import SubTitle from '../../../shared/UIKit/SubTitle';
import Image from '../../../shared/Image';

// Style
import './index.scss';
import Link from '../../../shared/UIKit/Link';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function AboutUs() {
  // localisation
  const { t } = useTranslation();

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const { title, contentTitle, contentParagraph, buttonText } = t('aboutUs', {
    returnObjects: true,
  });

  /* ******************************** RENDERING ******************************* */

  return (
    <section className="about-us ">
      <span id="about-us" />
      <div className="container scroll-reveal">
        <Container fluid>
          <SubTitle>{title}</SubTitle>
          <Row align="center">
            <Col lg={6} sm={12} md={12}>
              <div className="about-us-img-container">
                <Image src="about-us-img.png" alt="about us image" />
              </div>
            </Col>
            <Col lg={6} sm={12} md={12} className="about-us-content">
              <h3 className="about-us__title">{contentTitle}</h3>
              <p className="about-us__paragraph">{contentParagraph}</p>
              <Link to="/about-us">{buttonText}</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

AboutUs.propTypes = {};

AboutUs.defaultProps = {};

export default AboutUs;
