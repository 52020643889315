/* eslint-disable no-shadow */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import FadeIn from 'react-fade-in/lib/FadeIn';

// UI lib comoponents
import { Container, useScreenClass } from 'react-grid-system';

// UI local components
import Linkshared from '../../../shared/UIKit/Link';
import SubTitle from '../../../shared/UIKit/SubTitle';

// style
import './index.scss';
// APIs
import { API_PARTNERS, API_UPLOADS } from '../../../shared/APIs/index';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function OurPartners() {
  /* ******************************** CONSTANTS ******************************* */
  // Localisation
  const { t } = useTranslation();
  const { language } = useI18next();
  const { title, link } = t('ourPartners', {
    returnObjects: true,
  });
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm'].includes(screenClass);
  const IS_LARGE_DEVICE = ['xxl'].includes(screenClass);
  let numberOfItemsToShow = 6;

  if (IS_MOBILE) {
    numberOfItemsToShow = 2;
  } else if (IS_LARGE_DEVICE) {
    numberOfItemsToShow = 8;
  } else {
    numberOfItemsToShow = 6;
  }

  /* ****************************** HOOKS ******************************* */
  const [partnersList, setPartnersList] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(numberOfItemsToShow);
  /* --------------------------------- HELPERS -------------------------------- */
  // fetch data
  async function getPartners() {
    const {
      data: { data },
    } = await axios(API_PARTNERS(language));

    setPartnersList(data);
  }
  // Effect : get Partners from api
  useEffect(() => {
    getPartners();
  }, []);
  // Effect : this effect allows us to update slice parameters each 4s to replace items with new ones from partners List
  useEffect(() => {
    const Interval = setTimeout(() => {
      if (end > partnersList.length) {
        setEnd(numberOfItemsToShow);
        setStart(0);
      } else if (end < partnersList.length) {
        setEnd((end) => end + numberOfItemsToShow);
        setStart((start) => start + numberOfItemsToShow);
      }
    }, 3000);
    return () => clearInterval(Interval);
  }, [end, start, partnersList, IS_MOBILE, numberOfItemsToShow]);

  /* ******************************** RENDER HELPERS ******************************* */
  const placeholders = partnersList?.slice(start, end)?.map((partner) => {
    const {
      id,
      attributes: { category, images },
    } = partner;
    return (
      <div>
        <Link key={id} to="/partners" state={{ category }} className="slide">
          <FadeIn>
            <img
              src={API_UPLOADS(images?.data[0].attributes.url)}
              alt=""
              aria-hidden="true"
            />
          </FadeIn>
          <p className="partners-category">
            <span>#</span>
            {category}
          </p>
        </Link>
      </div>
    );
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="our--partners" id="partners-section" fluid>
      <div className="container scroll-reveal">
        <SubTitle>{title}</SubTitle>
      </div>
      <div className="placeholders-container">{placeholders}</div>
      <div className="container">
        <div className="contact-link-container">
          <Linkshared to="/partners">{link}</Linkshared>
        </div>
      </div>
    </Container>
  );
}

OurPartners.propTypes = {};

OurPartners.defaultProps = {};

export default OurPartners;
