/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

// UI lib comoponents

// Local UI components
import Button from '../../../shared/UIKit/Button';
import Image from '../../../shared/Image';

// assets

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Slogan() {
  // localisation
  const { t } = useTranslation();

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */
  const { title, paragraph, buttonText } = t('slogan', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */

  return (
    <section className="slogan">
      <div className="container">
        <Container fluid>
          <Row>
            <Col lg={8} sm={12} md={12} className="slogan-content">
              <h1 className="slogan__title">{title}</h1>
              <p className="slogan__paragraph">{paragraph}</p>
              <div className="slogan-button-container">
                <Button navigation="about-us">{buttonText}</Button>
              </div>
            </Col>
            <Col lg={4}>
              <div className="slogan-image-container">
                <Image
                  src="slogan-img.png"
                  alt="Slogan image"
                  aria-hidden="true"
                  className="slogan__image"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

Slogan.propTypes = {};

Slogan.defaultProps = {};

export default Slogan;
