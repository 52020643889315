/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// UI lib comoponents
import Carousel from 'react-elastic-carousel';
import Linkshared from '../../../shared/UIKit/Link';
import SubTitle from '../../../shared/UIKit/SubTitle';
// import SliderItem from '../../../shared/UIKit/SliderItem';

import SliderPress from '../../../shared/UIKit/SliderPress';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here
// APIS
import { API_UPLOADS, API_PRESSES } from '../../../shared/APIs';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Press() {
  /* ******************************** CONSTANTS ******************************* */
  // carousel props
  const CrouselSettings = {
    showArrows: false,
    pagination: false,
    enableAutoPlay: true,
    autoPlaySpeed: 5000,
    breakPoints: [
      { Width: 800, itemsToShow: 1, itemPadding: [0, 10] },
      { width: 850, itemsToShow: 1.2, itemPadding: [0, 20] },
    ],
  };
  /* ******************************** HOOKS******************************* */
  const CarouselEl = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [pressData, setPressData] = useState();
  const { t } = useTranslation();
  const { Title, link } = t('press', {
    returnObjects: true,
  });
  // detect the user language from path URL
  const { language } = useI18next();
  /* --------------------------------- HELPERS -------------------------------- */
  async function getPressesData() {
    const { data } = await axios(API_PRESSES(language));
    setPressData(data);
  }
  // Effect
  useEffect(() => {
    getPressesData();
  }, []);

  /* ******************************** RENDER HELPERS ******************************* */
  const actualities = pressData?.data?.map(({ id, attributes }) => {
    const { title, description, url, images } = attributes;
    return (
      <SliderPress
        key={id}
        slideTitle={title}
        slideDesc={description}
        imgURL={API_UPLOADS(images?.data[0].attributes.formats.small?.url)}
        navigationPath={url}
      />
    );
  });

  function NavigationLines() {
    if (pressData?.data === undefined || pressData?.data?.length === 0) {
      return null;
    }
    return pressData?.data?.map((_, index) => {
      return (
        <button
          type="button"
          className={`${index === activeSlide ? 'active' : ''}`}
          onClick={() => {
            setActiveSlide(index);
            CarouselEl.current.goTo(index);
          }}
        >
          {index + 1}
        </button>
      );
    });
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <section className="press">
      <span id="press-section" />
      <div className="container scroll-reveal">
        <SubTitle>{Title}</SubTitle>
        <div className="carousel-container">
          <div className="slider-navigation">
            <NavigationLines />
          </div>
          <Carousel
            {...CrouselSettings}
            ref={CarouselEl}
            onChange={(_, pageIndex) => setActiveSlide(pageIndex)}
          >
            {actualities}
          </Carousel>
        </div>
        <div className="press-link-container">
          <Linkshared to="/press">{link}</Linkshared>
        </div>
      </div>
    </section>
  );
}

Press.propTypes = {};

Press.defaultProps = {};

export default Press;
